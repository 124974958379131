import { Metadata } from "next";
import {
  LuAccessibility,
  LuAward,
  LuBookMarked,
  LuGamepad2,
  LuHome,
  LuHotel,
  LuMedal,
  LuMonitorDot,
  LuPaintbrush,
  LuPenTool,
  LuShare2,
  LuSmile
} from "react-icons/lu";

export const SEO = {
  SITE_URL: process.env.NODE_ENV === "development" ? 'http://localhost:3000/' : "https://mee.fund/",
  SITE_TITLE: "MeeFund - Komunitas kreatif yang penuh inovasi dan hiburan terbaik mu!",
  SITE_DESCRIPTION:
    "Panggung buat ide-idemu yang paling gokil. Mulai dari proyek kreatif, inovasi, sampai hiburan seru, semua bisa dapat tempat di MeeFund. Jadi, tunggu apa lagi? Saatnya unjuk gigi!",
  SITE_AUTHORS: [
    { name: "zaadevofc", url: "https://zaadevofc.tech" },
    { name: "zaadevofc", url: "https://instagram.com/zaadevofc" },
  ],
  SITE_CREATOR: "zaadevofc",
  SITE_CREATOR_EMAIL: "zaadevofc@gmail.com",
  SITE_CATEGORY: "Crowdfunding, Social Media, Kreativitas",
  SITE_KEYWORDS: [
    "meefund",
    "crowdfunding indonesia",
    "platform kreativitas",
    "pendanaan ide",
    "inovasi",
    "konten kreatif",
    "sosial media indonesia",
    "hiburan online",
    "proyek kreatif",
    "komunitas inovatif",
    "startup indonesia",
    "pendidikan kreatif",
  ],
};

export const METADATA: Metadata = {
  metadataBase: new URL(SEO.SITE_URL),
  title: {
    default: SEO.SITE_TITLE,
    template: `%s | ${SEO.SITE_TITLE}`,
  },
  description: SEO.SITE_DESCRIPTION,
  authors: SEO.SITE_AUTHORS,
  creator: SEO.SITE_CREATOR,
  category: SEO.SITE_CATEGORY,
  keywords: SEO.SITE_KEYWORDS,
  appLinks: { web: { url: SEO.SITE_URL } },
  alternates: { canonical: SEO.SITE_URL },
  applicationName: "MeeFund",
  bookmarks: SEO.SITE_URL,
  publisher: SEO.SITE_CREATOR,
  robots: {
    index: true,
    follow: true,
    googleBot: {
      index: true,
      follow: true,
      'max-video-preview': -1,
      'max-image-preview': 'large',
      'max-snippet': -1,
    },
  },
  openGraph: {
    url: SEO.SITE_URL,
    siteName: SEO.SITE_TITLE,
    locale: 'id_ID',
    type: "website",
    description: SEO.SITE_DESCRIPTION,
    title: SEO.SITE_TITLE,
    images: [SEO.SITE_URL + "android-chrome-512x512.png"],
  },
  twitter: {
    card: "summary_large_image",
    site: "@meefund",
    creator: "@" + SEO.SITE_CREATOR,
    title: SEO.SITE_TITLE,
    description: SEO.SITE_DESCRIPTION,
    images: [SEO.SITE_URL + "android-chrome-512x512.png"],
  },
  viewport: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no',
  appleWebApp: {
    title: SEO.SITE_TITLE,
    capable: true,
    statusBarStyle: 'default',
    startupImage: [
      '/apple-touch-icon.png',
    ],
  },
  icons: {
    icon: [
      { url: '/favicon-32x32.png', sizes: '32x32', type: 'image/png' },
      { url: '/favicon-16x16.png', sizes: '16x16', type: 'image/png' },
    ],
    apple: [
      { url: '/apple-touch-icon.png', sizes: '180x180', type: 'image/png' },
    ],
    other: [
      { rel: 'mask-icon', url: '/safari-pinned-tab.svg', color: '#5bbad5' },
    ],
  },
  manifest: '/site.webmanifest',
  themeColor: '#ffffff',
  other: {
    'msapplication-TileColor': '#da532c',
    'google-adsense-account': 'ca-pub-6374133597923635',
  },
};

export const RAINBOW_TEXT = [
  "meefund",
  "mantra",
  "pelangi",
  "rainbow",
  "indonesia"
]

export const POST_CATEGORY = [
  { icon: LuHome, label: "Beranda", href: "/" },
  { icon: LuBookMarked, label: "Pendidikan", href: "/pendidikan" },
  { icon: LuMonitorDot, label: "Coding", href: "/coding" },
  { icon: LuShare2, label: "Sharing", href: "/sharing" },
  { icon: LuAward, label: "Pameran", href: "/pameran" },
  { icon: LuAccessibility, label: "Tanya Sepuh", href: "/tanya-sepuh" },
  { icon: LuSmile, label: "Meme", href: "/meme" },
  { icon: LuPenTool, label: "Kata Kata", href: "/kata-kata" },
  { icon: LuPaintbrush, label: "Karya Seni", href: "/karya-seni" },
  { icon: LuMedal, label: "Info Lomba", href: "/info-lomba" }
]
